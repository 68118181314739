import React from 'react'
import Header from '../../component/Header/Header'
import Job from './Job'
import Footer from '../../component/Footer/Footer'
function Careers() {
  return (
    <div>
        <Header/>
        <Job/>
        <Footer/>
    </div>
  )
}

export default Careers